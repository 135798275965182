import {Grid, IconButton} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import {Box} from '@mui/system';
import {GridCellParams} from '@mui/x-data-grid-pro';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router';
import PageHeadline from '../../components/PageHeadline';
import {useAxiosContext} from '../../context/AxiosContext';
import {EventItem, EventOrder} from '../../model/Event';
import {ResponseListWrapper} from '../../services/model/ResponseListWrapper';
import {PageStickyHeader} from '../PageStickyHeader';
import {PageTopActions} from '../PageTopActions';
import OrderDetailDialog from './dialogs/OrderDetailDialog';
import useApiRequest from '../../hooks/useApiRequest';
import DataGridCustom from '../../components/DataGridCustom';
import {useTableColumns} from '../UseTableColumns';
import {FeatureName} from '../../../paths';
import {ColumnSizes} from '../../components/shared/ColumnSize';
import {usePermissions, UsePermissionState} from '../UsePermissions';
import {TestAttributes} from '../../TestAttributes';
import {GridColDef} from '@mui/x-data-grid';

function EventOrdersViewPage() {
  const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const [actionColumn, setActionColumn] = useState<GridColDef>();
  const navigate = useNavigate();
  const {useAxiosOutletOrderAPI: useAxiosEventAPI} = useAxiosContext();
  const {id, orderId} = useParams();
  const {t} = useTranslation();
  const {userPermissions}: UsePermissionState = usePermissions(FeatureName.EVENT_ORDERS);

  const {entityData: entityListData, reFetch: getEntityData} = useApiRequest<ResponseListWrapper<EventOrder>>({
    useAxios: useAxiosEventAPI,
    config: {url: `outlet-orders`, params: {outlet_id: id}},
    options: {manual: false, useCache: false},
    pageName: 'EventOrdersListPage',
  });
  const {entityData} = useApiRequest<EventItem>({
    useAxios: useAxiosEventAPI,
    config: {
      url: `/outlets/${id}`,
      params: {
        formatted: true,
        type: 'event',
      },
    },
    options: {manual: false},
    ignoreLoader: true,
  });

  useEffect(() => {
    setActionColumn({
      field: 'actions',
      headerName: t('table-columns.actions'),
      width: ColumnSizes.MD,
      renderCell: (params: GridCellParams) => {
        return (
          <IconButton
            id="campaign-list-more-actions-btn"
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setIsOrderDetailsOpen(true);
              setSelectedOrderId(params.row.outlet_order_id);
              navigate(`/event-orders/${id}/${params.row.outlet_order_id}`); // Update the URL
            }}
            size="large"
          >
            <Visibility />
          </IconButton>
        );
      },
    });
  }, []);

  useEffect(() => {
    if (orderId) {
      setSelectedOrderId(orderId);
      setIsOrderDetailsOpen(true);
    }
  }, [orderId]);

  const tableColumns = useTableColumns({
    featureName: FeatureName.EVENT_ORDERS,
    actionColumn: actionColumn,
  });

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
      }}
    >
      <PageStickyHeader sx={{minHeight: '85px'}}>
        <Grid container item xs={12} rowSpacing={{xs: 3, sm: 3}}>
          <Grid item xs={6}>
            <PageHeadline>
              {t('event-order.headline')}: {entityData?.name}
            </PageHeadline>
          </Grid>
          <Grid item xs={6}>
            <PageTopActions></PageTopActions>
          </Grid>
        </Grid>
      </PageStickyHeader>
      <Box sx={{gap: 2, p: 0, m: 2}}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{height: 'calc(100vh - 64px - 85px);'}}
            {...{[TestAttributes.TABLE_NAME]: 'table-results'}}
          >
            {entityListData && (
              <DataGridCustom
                tableColumns={tableColumns}
                rows={entityListData.results.map((order, index) => {
                  return {...order, id: index};
                })}
                userPermissions={userPermissions}
              />
            )}
          </Grid>
        </Grid>
        {selectedOrderId && (
          <OrderDetailDialog
            orderId={selectedOrderId}
            onClose={() => {
              setIsOrderDetailsOpen(!isOrderDetailsOpen);
              setSelectedOrderId(null);
              navigate(`/event-orders/${id}`); // Reset the URL when the dialog is closed
            }}
            isOpen={isOrderDetailsOpen}
            getEntityListData={getEntityData}
          />
        )}
      </Box>
    </Box>
  );
}

export default EventOrdersViewPage;
